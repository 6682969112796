import React, { useEffect } from 'react';

import './App.css';
import NavbarTop from './components/Nav/NavbarTop';
import NavbarBottom from './components/Nav/NavbarBottom';
import { Helmet } from 'react-helmet';
import ScrollToTopButton from './components/ScrollUp/ScrollToButton';
import ScrollToTop from './components/ScrollUp/ScrollToTop';
import AnimatedRoutes from './components/Routes/AnimatedRoutes';
import { AuthProvider } from './contexts/AuthContext/AuthContext';

function App() {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet='utf-8' />
				<link rel='icon' href='%PUBLIC_URL%/favicon.ico' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1'
				/>
				<meta name='theme-color' content='#000000' />
				<title>Private Voice Lessons | Color Your Voice</title>
				<meta
					name='description'
					content='Private Voice Lessons for all ages'
				/>
				<meta
					name='keywords'
					content='Voice Lessons, Singing, Singing Teacher, Online Voice Lessons, Voice Teacher, Private Voice Lessons, Color Your Voice, Grace Wall'
				/>
				<meta
					property='og:image'
					content='%PUBLIC_URL%/share-img.jpg'
				/>
			</Helmet>
			<AuthProvider>
				<ScrollToTop />
				<div className='container-flex'>
					<NavbarTop />
					<AnimatedRoutes />
				</div>
				<ScrollToTopButton />
				<NavbarBottom />
			</AuthProvider>
		</>
	);
}

export default App;
