import React from 'react';
import {
	Container,
	Card,
	Row,
	Col,
	Image,
	Button,
	ListGroup,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Snowfall from 'react-snowfall';
import snowLogo from '../../assets/summerWinterLogo.png';

// Import assets
import logo from '../../assets/LOGOcolor.png';
import headline from '../../assets/HLDlogo.png';

// Import components
import Faculty from '../../components/Faculty/Faculty';
import Quotes from '../../components/Testimonials/Quotes';
import WhatYouLearn from '../../components/WhatYouLearn/WhatYouLearn';
import Contact24 from '../Members/Contact24';

import WinterSummerModal from '../../components/Modals/WinterSummerModal';

const Home = () => {
	return (
		<>
			{/* Winter & Summer Modal */}
			{/* <WinterSummerModal /> */}

			{/* Hero Section */}
			<Container fluid className='mt-4 text-center'>
				<div className='text-end mb-3'>
					<a
						variant=''
						href='/login'
						className='border-0 bg-transparent  fs-6 px-5'>
						log in{' '}
						<i class='fa-duotone fa-person-to-portal fa-lg'></i>
					</a>
				</div>

				<motion.h1
					initial={{ opacity: 0, y: -50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.8 }}
					className='brand-heading display-4'>
					Voice Lessons for All Ages
				</motion.h1>
			</Container>
			<Container fluid className='mb-5 mx-0 px-0'>
				<Row className='justify-content-evenly text-center'>
					<Col xs={12} sm={12} md={9} lg={8} xl={7}>
						<Image
							width='80%'
							className='img-responsive'
							src={logo}
							fluid
						/>
					</Col>
				</Row>
				<Row className='m-0'>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						className='d-flex justify-content-center'>
						<motion.div
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{
								duration: 0.8,
								delay: 0.4,
								ease: [0, 0.71, 0.2, 1.01],
							}}>
							<ListGroup className='mt-3 border-0 p-0 bg-transparent fs-4'>
								<ListGroup.Item className='border-0 bg-transparent'>
									private voice lessons
								</ListGroup.Item>
								<ListGroup.Item className='border-0 bg-transparent'>
									group voice lessons
								</ListGroup.Item>
								<ListGroup.Item className='border-0 bg-transparent'>
									music literacy
								</ListGroup.Item>
								<ListGroup.Item className='border-0 bg-transparent'>
									audition coaching
								</ListGroup.Item>
							</ListGroup>
						</motion.div>
					</Col>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						className='mt-2 p-0 d-md-none'>
						<Image
							width='80%'
							className='p-0 img-responsive'
							src={headline}
							fluid
						/>
					</Col>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						className='mt-2 p-0 d-none d-md-block'>
						<Image
							width='50%'
							className='p-0 img-responsive'
							src={headline}
							fluid
						/>
					</Col>
				</Row>
				<motion.div
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{
						duration: 0.8,
						delay: 1.5,
						ease: [0, 0.71, 0.2, 1.01],
					}}>
					<Col
						xs={12}
						sm={12}
						md={12}
						lg={12}
						className='mt-0 mx-auto'>
						<Row className='justify-content-center text-center'>
							<Col xs={12}></Col>
						</Row>
					</Col>
				</motion.div>
			</Container>

			{/* Winter Recital Card */}
			{/* <Container fluid className='py-0'>
				<Row className='justify-content-center shadow-none'>
					<Col xs={12} md={10} lg={8}>
						<Card className='text-center p-4 shadow-none border-0 background-winter-summer'>
							<Card.Body
								style={{ minWidth: '19rem' }}
								className='p-4 bg-white shadow-none border-0 rounded'>
								<h2 className='fw-bold'>Winter Recital 2025</h2>
								<p className='mb-4 fs-5'>
									<strong>Two Shows</strong> - Sunday, Jan 12
									<sup>th</sup>
								</p>
								<Row className='g-2 '>
									<Col xs={12} md={6}>
										<Button
											variant='secondary'
											size='lg'
											className='w-100'
											href='https://www.ticketleap.events/tickets/color-your-voice/color-your-voice-winter-recital-show-1-259849783'
											target='_blank'>
											1:00 PM
										</Button>
									</Col>
									<Col xs={12} md={6}>
										<Button
											variant='warning'
											size='lg'
											className='w-100'
											href='https://www.ticketleap.events/tickets/color-your-voice/color-your-voice-winter-recital-show-2'
											target='_blank'>
											4:00 PM
										</Button>
									</Col>
								</Row>
								<p className='mt-3 fs-6 text-muted'>
									Buy Tickets Now!
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container> */}

			{/* SUMMER HOMEPAGE CARD */}
			<Container fluid className='py-0'>
				<Row className='justify-content-center shadow-none'>
					<Col xs={12} md={10} lg={8}>
						<Card className='text-center p-4 shadow-none border-0 background-summer'>
							<Card.Body
								style={{ minWidth: '19rem' }}
								className='p-4 bg-white shadow-none border-0 rounded'>
								<h2 className='fw-bold'>
									Summer Offerings 2025
								</h2>
								<p className='mb-4 fs-6'>
									Private Voice Lessons • Acting Boot Camp •
									Audition Coaching and more!
								</p>

								<Button
									variant='info'
									className='px-4'
									href='/sign-up'>
									JOIN OUR WAITLIST
								</Button>

								<p className='mt-3 fs-6 text-muted'>
									Details will be posted soon!
								</p>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
			{/* Join Our Waitlist Card */}
			<Container fluid className='py-5'></Container>

			{/* Additional Sections */}
			<Container fluid className='p-3' id='about'>
				<Faculty />
			</Container>
			<Container fluid className='p-3 background-blue'>
				<WhatYouLearn />
			</Container>
			<Container fluid className='p-3' id='testimonials'>
				<Quotes />
			</Container>
			<Container fluid className='p-3 background-blue' id='contact'>
				<Contact24 />
			</Container>
		</>
	);
};

export default Home;
